.container {
  @apply h-[500px] max-w-[800px];
  grid-template-columns: 8fr 7fr;
}

.opacity-animation {
  animation: opacityAnimation 0.5s linear;
  transform: translateY(0px);
}

@keyframes opacityAnimation {
  0% {
    opacity: 1;
  }

  50% {
    opacity: 0;
    transform: translateY(20px);
  }

  100% {
    opacity: 1;
    transform: translateY(0px);
  }
}

@media screen and (max-width: 1024px) {
  .container {
    @apply h-[450px] max-w-[580px];
    grid-template-columns: 4fr 3fr;
  }
}

@media screen and (max-width: 768px) {
  .container {
    @apply max-w-full;
    grid-template-columns: 1fr;
    height: fit-content;
  }

  .image {
    min-height: 400px;
  }
}