.condense-container {
  max-height: 42px;
  transition: all 0.5s cubic-bezier(.44, .99, .48, 1);
}

.container {
  max-width: 840px;
  width: 100%;
}

.show-container {
  animation: toTop 0.5s ease-in forwards;
  transform: translateY(-10px);
}

@keyframes toTop {
  0% {
    transform: translateY(0px);
    opacity: 0;
  }

  100% {
    transform: translateY(10px);
    opacity: 1;
  }
}

@media screen and (max-width: 1024px) {
  .container {
    max-width: 680px;
  }
}