.expand-container {
  display: block;
  max-height: fit-content;
  animation: opacity 0.5s linear;
}

@keyframes opacity {
  0% {
    opacity: 0;
    height: 0px;
  }

  100% {
    opacity: 1;
    height: 360px;
  }
}

.condense-container {
  animation: disappear 0.5s linear;
}

@keyframes disappear {
  0% {
    opacity: 1;
    height: 360px;
  }

  100% {
    opacity: 0;
    height: 0px;
    display: none;
  }
}

.container {
  max-width: 750px;
}

@media screen and (max-width: 1024px) {
  .container {
    max-width: 620px;
  }
}